import React, { useEffect, useRef } from 'react';

import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import ArrowButtonText from '../ArrowButtonText';

import './index.css';

const CareerView = () => {
  const ref = useRef();
  //Get job id from query string
  const location = useLocation();
  const query = location.search ? queryString.parse(location.search) : '';
  const { gh_jid } = query;
  return (
    <>
     {/* The greenhouse script is inserted in gatsby-ssr */}
      <section id="career-view" className="career-view">
        <div className="link-container">
          { gh_jid &&
            <a className="greenhouse-link"  href={'https://boards.greenhouse.io/devetry/jobs/' + gh_jid }> 
              <ArrowButtonText text="View Job Posting on Greenhouse" />
            </a>
          }
        </div>
        <div ref={ref} id="grnhse_app"> 
          <div className="spinner">
              <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerView;
