import React, { useEffect, useContext } from 'react';

import CarrerView from '../../components/CareerView';
import AppContext from '../../contexts/AppContext';
import InboxSection from '../../components/blogetrySections/InboxSection';

const CareerListing = () => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('people');
  }, [setActivePage]);

  return (
    <div className="root-container">
      <CarrerView />
      <InboxSection />
    </div>
  );
};

export default CareerListing;
